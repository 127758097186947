<template>
  <div class="container">
    <tab-header :headerList="boss.anaylsisList" :currentTab="2" @changeTabPage="changeTabPage"></tab-header>
    <!--时间选择-->
    <time-select @timeChange="timeChange"></time-select>
    <div class="scroll-box">
      <div class="main-content">
          <member-card :dictionaryData="rechargeData"></member-card>
          <member-recharge-chart :chartData="rechargeCharts"></member-recharge-chart>
          <member-card :dictionaryData="consumeData"></member-card>
          <member-consume-chart :chartData="consumeCharts"></member-consume-chart>
          <member-card :dictionaryData="remainData"></member-card>
          <member-card :dictionaryData="frozenData"></member-card>
      </div>
      <daqi-copyright></daqi-copyright>
    </div>
  </div>
</template>

<script>
import table from '@/api/table'
import {mapState} from 'vuex'
export default {
  name:"member-analysis",
  data(){
    return{
				//同步时间
				timeParams:null,
                //会员充值
				rechargeData:null,
                //会员充值走势图
				rechargeCharts:null,
                //会员消费
				consumeData:null,
                //会员消费走势图
				consumeCharts:null,
                //会员余额
				remainData:null,
                //冻结金额
				frozenData:null
    }
  },
  computed:{
    ...mapState(['boss','userStatus'])
  },
  watch:{
    timeParams:{
      handler(newVal,oldVal){
        if(! newVal){
          return
        }
        if(! ! oldVal && newVal.startTime === oldVal.startTime){
          return
        }
        this.getMemberByTime()
      },
      deep:true,
      immediate:true,
    },
    'userStatus.curs_node_id_2':{
      handler: 'getMemberByTime',
      deep:true
    }
  },
  components:{
    'tab-header':() => import('@/components/tab-header.vue'),
    'time-select':() => import('@/components/time-select'),
    'member-card':() => import('./member/member-card'),
    'member-recharge-chart':() => import('./member/member-recharge-chart'),
    'member-consume-chart':() => import('./member/member-consume-chart'),
    'daqi-copyright':() => import('@/components/daqi-copyright')
  },
  methods:{
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    },
    /*时间切换*/
    timeChange(params){
      this.timeParams = params
    },
    /*获取会员充值信息*/
    getMemberByTime(){
      let {timeParams:{startTime,endTime}} = this
      let params = {startTime,endTime}
      table.getMemberByTime(params).then(res =>{
					let data = res.data
                    let recharge = data.recharge_account
                    let consume = data.consume_account
                    let remain = data.remain_account
                    let frozen = data.frozen_account
					let  rechargeData = {
						title: "会员充值",
						totalLabel:'会员充值总计(元)',
            total:recharge.total_recharge,
            realLabel: '实际会员充值(元)',
            real: recharge.member_recharge,
            giftLabel:'会员卡赠送(元)',
            gift: recharge.recharge_gift,
            time: recharge.day2day
          }
					let  consumeData = {
						title: "会员消费",
						totalLabel:'会员消费总计(元)',
						total:consume.total_consume,
						realLabel: '实际会员消费(元)',
						real: consume.member_consume,
						giftLabel:'会员卡赠送消费(元)',
						gift: consume.consume_gift
					}
					let remainData = {
						title: "会员余额",
						totalLabel:'会员余额总计(元)',
						total:remain.total_consume,
						realLabel: '实际充值余额(元)',
						real: remain.member_remain,
						giftLabel:'赠送余额(元)',
						gift: remain.remain_gift
					}
					let frozenData = {
						title: "冻结金额",
						totalLabel:'冻结金额总计(元)',
						total: frozen.total_frozen
                    }
					this.rechargeData = rechargeData
          this.consumeData = consumeData
          this.remainData = remainData
          this.frozenData = frozenData
          this.rechargeCharts = data.recharge_charts
          this.consumeCharts = data.consume_charts
          // console.log(this.consumeCharts.member_consume_list)
      }).catch(err =>{
        console.log(err.info)
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.container{
    position: relative;
}
.scroll-box{
    flex:1;
    overflow:auto;
    width:100%;
}
.main-content{
    min-height:calc(100% - 101px);
}
</style>
